@import 'styles/includes.scss';

.CardNews {
    $root: &;
    position: relative;
    width: 100%;
    background: #fff;
    transition: all $transition;

    &:last-child {
        display: none;
    }

    @include media(s) {
        width: calc(50% - 12px);

        &:last-child {
            width: 100%;
            display: flex;
        }
    }

    @include media(m) {
        width: 33%;

        &:last-child {
            width: 33%;
            display: block;
        }
    }

    &__Inner {
        display: flex;
        flex-direction: column;
        color: $colorBlack;
        text-decoration: none;
        text-align: left;
        padding: 16px 16px 32px;
        text-decoration: none;

        @include media(m) {
            padding: 16px 16px 54px 16px;
        }

        #{$root}:hover & {
            text-decoration: none;
        }
    }

    &__Label {
        display: inline-block;
        font-family: $fontRaleway;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        color: $colorBlack;
        width: 100%;
    }

    &__Title {
        @extend %h3;
        margin-bottom: 4px;
        position: relative;
        display: inline-block;
        margin-right: 0;
        width: fit-content;
        margin-top: 5px;

        #{$root}:hover & {
            text-decoration: underline;
        }
    }

    &__Image {
        height: 180px;
        width: 100%;
        position: relative;

        @include media(s) {
            #{$root}:last-child & {
                height: 100%;
                height: 200px;
            }
        }

        @include media(m) {
            height: 204px;

            #{$root}:last-child & {
                height: 204px;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            left: 0;
        }
    }

    &__Text {
        @extend %body-copy-small;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @include media(s) {
            -webkit-line-clamp: 3;
        }
    }

    &__Arrow {
        position: absolute;
        height: 25px;
        width: 25px;
        right: 2.2rem;
        bottom: 1.6rem;
        transition: all $transition;

        #{$root}:hover & {
            right: 1.6rem;
        }
    }
}
