@import 'styles/includes.scss';

.CardListModule {
    padding-top: 30px;

    @include media(m) {
        padding-top: 60px;
    }

    &__TopContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
        margin-bottom: 30px;
    }

    &__Title {
        @extend %h2;
        color: $colorBlack;
        font-family: $fontRaleway;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 5px;
            background-color: #557045;
            margin-top: 10px;
        }
    }

    &__Title--Blue {
        &::after {
            background-color: $newColorBlue;
            content: '';
            display: block;
            width: 100%;
            height: 5px;
            margin-top: 10px;
        }
    }

    &__Text {
        @extend %container;
        @extend %body-copy;
    }

    &__Container {
        @extend %container;
        text-align: center;
    }

    &__Inner {
        display: flex;
        flex-flow: column;
        gap: 24px;

        @include media(s) {
            flex-flow: row;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        @include media(m) {
            flex-flow: nowrap;
        }
    }
}
